import { OBSERVER } from '../plugins';

// Permet de créer des boutons dynamiques pour supprimer le contenu d'un ou plusieurs champs de texte
export default function dynamicClearInput() {
  const activeClassName = 'button-clear--active';
  const buttonsSelector = 'data-clear-input-button';
  const inputsSelector = 'data-clear-input';

  let i;
  let j;

  const onClick = (e) => {
    const button = e.currentTarget;
    const valueFromButton = button.dataset.clearInputButton;

    // Supprime le contenu de tous les champs de texte du même type
    const inputs = document.querySelectorAll(`[${inputsSelector}="${valueFromButton}"]`);
    const inputsLength = inputs.length;
    for (i = inputsLength - 1; i >= 0; i -= 1) {
      const input = inputs[i];
      input.value = '';
      input.parentElement.parentElement.dataset.textFieldValue = '';
      input.classList.remove('valid');

      if (i === 0) {
        input.focus();
      }
    }

    // Supprime la classe active de tous les boutons du même type
    const buttons = document.querySelectorAll(`[${buttonsSelector}="${valueFromButton}"]`);
    const buttonsLength = buttons.length;
    for (i = buttonsLength - 1; i >= 0; i -= 1) {
      buttons[i].classList.remove(activeClassName);
    }
  };

  const onInput = (e) => {
    const input = e.currentTarget;
    const valueFromInput = input.dataset.clearInput;
    const inputs = document.querySelectorAll(`[${inputsSelector}="${valueFromInput}"]`);
    const inputsLength = inputs.length;

    // Vérifie si tout les champs sont vides
    let isAllEmpty = true;
    for (i = inputsLength - 1; i >= 0; i -= 1) {
      if (inputs[i].value.toString().length !== 0) {
        isAllEmpty = false;
      }
    }

    // Ajoute ou supprime la classe active sur le bouton en fonction de la variable isAllEmpty
    const buttons = document.querySelectorAll(`[${buttonsSelector}="${valueFromInput}"]`);
    const buttonsLength = buttons.length;
    for (i = buttonsLength - 1; i >= 0; i -= 1) {
      const button = buttons[i];

      if (isAllEmpty) {
        button.classList.remove(activeClassName);
      } else {
        button.classList.add(activeClassName);
      }
    }
  };

  // Ajout des classes actives sur les boutons dont les champs ne sont pas vide
  const onStart = () => {
    const inputs = document.querySelectorAll(`[${inputsSelector}]`);
    const inputsLength = inputs.length;

    for (i = inputsLength - 1; i >= 0; i -= 1) {
      const input = inputs[i];

      if (input.value.toString().length !== 0) {
        const datasetValueFromInput = input.dataset.clearInput;
        const buttons = document.querySelectorAll(`[${buttonsSelector}="${datasetValueFromInput}"]`);
        const buttonsLength = buttons.length;

        for (j = buttonsLength - 1; j >= 0; j -= 1) {
          buttons[j].classList.add(activeClassName);
        }
      }
    }
  };

  onStart();

  OBSERVER.add({
    name: 'clearInput',
    events: 'input',
    targets: `[${inputsSelector}]`,
    function: onInput,
  });

  OBSERVER.add({
    name: 'clearInput',
    events: 'click',
    targets: `[${buttonsSelector}]`,
    function: onClick,
  });

  OBSERVER.on('clearInput');
}
