import Accordions from '../classes/accordions';

// Permet d'initialiser les rubrics avec un offset dynamique ou sans de offset
// Pour un offset dynamique, on doit ajouter des fonctions (ex: onResize, onCloseAlert) pour changer le offset
export function rubrics() {
  const rubric = new Accordions();
  rubric.offset = 0;
  rubric.init();
}

export function promoRubrics() {
  const promoRubricsAccordions = new Accordions({
    containerClass: 'js-promo-rubrics',
    accordionClass: 'js-promo-rubric',
    toggleButtonClass: 'js-promo-rubric-toggle',
    contentClass: 'js-promo-rubric-content',
    openedClass: 'js-promo-rubric-opened',
  });

  promoRubricsAccordions.init();
}
