import $ from 'jquery';
import { OBSERVER, PARALLAX } from '../plugins';

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.calendar').dataset.list;
  const currentDate = document.querySelector('.calendar').dataset.date;

  $.fn.datepicker.dates.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre',
      'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0,
  };

  const datesEnabled = eventsDatesList.split(',');

  $('.calendar')
    .datepicker({
      language: 'fr',
      maxViewMode: 0,
      format: 'yyyy-mm-dd',
      todayHighlight: true,
      beforeShowDay: (date) => {
        // Rendre seulement les dates de la liste d'événements disponibles
        let returnBoolean;
        const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
        if (datesEnabled.indexOf(allDates) !== -1) {
          returnBoolean = true;
        } else {
          returnBoolean = false;
        }
        return returnBoolean;
      },
    })
    .on('changeDate', (e) => {
      // Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
      const theTimestamp = Date.parse(e.date) / 1000;
      $.request('onChangeDate', {
        data: { dateFilter: theTimestamp },
        // update: {'evenementlist::eventslist':'#eventsWrapper'},
        // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
        complete: (data) => {
          data.then((data2) => {
            // eslint-disable-next-line no-undef
            Barba.Pjax.goTo(`/evenements/1/${data2.date}`);
          });
        },
      });
      // Fermer l'overlay quand on clique
      $('#overlayCalendar .wrapper a.close span.x').trigger('click');
    });

  $('.calendar').datepicker('update', currentDate);
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i;
  const anchors = document.querySelectorAll('a[href]');
  for (i = 0; i < anchors.length; i += 1) {
    // eslint-disable-next-line no-script-url
    if (anchors[i].target !== '_blank' && anchors[i].href !== 'javascript:;') {
      if (
        window.location.hostname !== anchors[i].hostname
        || anchors[i].href.match('\\.pdf$')
        || window.location.protocol !== anchors[i].protocol
      ) {
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }
}

// Ajoute les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

// Initialisation du parallax
export function initParallax() {
  let isInit = false;

  const onResize = () => {
    const ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (ww > 768 && !isInit) {
      PARALLAX.init();
      isInit = true;
    } else if (ww <= 768 && isInit) {
      PARALLAX.destroy();
      isInit = false;
    }
  };

  onResize();

  OBSERVER.add({
    name: 'initParallax',
    events: 'resize',
    function: onResize,
  });

  OBSERVER.on('initParallax');
}

export function asideHeight() {
  const aside = document.querySelector('.js-get-aside-height');

  const onResize = () => {
    const ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (ww > 1000) {
      const height = aside.offsetHeight;
      document.documentElement.style.setProperty('--aside-height', `${height}px`);
    } else {
      document.documentElement.style.setProperty('--aside-height', 'auto');
    }
  };

  if (aside) {
    onResize();

    OBSERVER.add({
      name: 'asideHeight',
      events: 'resize',
      function: onResize,
    });

    OBSERVER.on('asideHeight');
  }
}

export function listLoaded(data) {
  if (!data.hasMorePages) {
    $('#listMoreBtn').hide();
  } else {
    $('#listMoreBtn').show();
  }
}
