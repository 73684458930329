export default function flippingText() {
  let i;
  const elements = document.querySelectorAll('[data-flipping-text]:not(.js-flip-initialized)');
  const elementsLength = elements.length;

  for (i = 0; i < elementsLength; i += 1) {
    const element = elements[i];
    const className = element.getAttribute('data-flipping-text');

    const textElement = element.querySelector('.js-flipping-text-to-clone');
    const text = textElement.textContent;

    const newElement = document.createElement('span');
    newElement.classList.add(`${className}__clone`);
    newElement.textContent = text;
    textElement.appendChild(newElement);

    element.classList.add('js-flip-initialized');
  }
}
