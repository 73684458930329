// Documentation: https://swiperjs.com/swiper-api
import Swiper from 'swiper/bundle';
import sliderBar from './slider-bar';

export function homeBannerSlider() {
  const totalSlidesLength = document.querySelectorAll('[data-swiper="home-banner-content"] .swiper-slide').length;
  const totalEl = document.querySelector('[data-swiper-pagination-total="home-banner-content"]');
  if (totalEl) {
    totalEl.innerHTML = totalSlidesLength;
  }

  const changeCurrent = (sw) => {
    if (totalEl) {
      const current = document.querySelector('[data-swiper-pagination-current="home-banner-content"]');
      current.innerHTML = sw.realIndex + 1;
    }
  };

  window.swiperHomeBannerContent = new Swiper('[data-swiper="home-banner-content"]', {
    autoplay: {
      delay: 5000,
    },
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    on: {
      init: (sw) => {
        changeCurrent(sw);
      },
      slideChange: (sw) => {
        changeCurrent(sw);
      },
    },
    slidesPerView: 1,
    speed: 700,
    threshold: 10,
  });

  window.swiperHomeBannerImages = new Swiper('[data-swiper="home-banner-images"]', {
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    loop: true,
    slidesPerView: 1,
    speed: 700,
    threshold: 10,
  });

  window.swiperHomeBannerContent.controller.control = window.swiperHomeBannerImages;
  window.swiperHomeBannerImages.controller.control = window.swiperHomeBannerContent;
}

export function stepsSlider() {
  const sliderName = 'steps';
  window.quotesSlider = new Swiper(`[data-swiper="${sliderName}"]`, {
    speed: 700,
    threshold: 10,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: `[data-swiper-pagination="${sliderName}"]`,
      clickable: true,
      renderBullet: (index) => {
        const selector = `[data-swiper="steps"] [data-swiper-slide-index="${index}"]`;
        const word = document.querySelector(selector).getAttribute('data-swiper-steps-word');
        return `
          <span class="swiper-pagination-bullet">
            <span class="c-steps-slider__index">${index + 1}</span>
            <span class="c-steps-slider__word">${word}</span>
          </span>
        `;
      },
    },
  });
}

export function quotesSlider() {
  const sliderName = 'quotes';
  window.quotesSlider = new Swiper(`[data-swiper="${sliderName}"]`, {
    speed: 700,
    threshold: 10,
    slidesPerView: 'auto',
    loop: true,
    navigation: {
      prevEl: `[data-swiper-prev="${sliderName}"]`,
      nextEl: `[data-swiper-next="${sliderName}"]`,
    },
    pagination: {
      el: `[data-swiper-pagination="${sliderName}"]`,
      type: 'bullets',
      clickable: true,
    },
    autoplay: {
      delay: 5000,
    },
  });

  sliderBar(sliderName, window.quotesSlider);
}

export function partnersSlider() {
  const sliderName = 'partners';
  window.partnersSlider = new Swiper(`[data-swiper="${sliderName}"]`, {
    speed: 700,
    threshold: 10,
    slidesPerView: 'auto',
    loop: true,
    navigation: {
      prevEl: `[data-swiper-prev="${sliderName}"]`,
      nextEl: `[data-swiper-next="${sliderName}"]`,
    },
    autoplay: {
      delay: 3000,
    },
  });
}

export function filtersSlider() {
  const sliderName = 'filters';
  window.filters = new Swiper(`[data-swiper="${sliderName}"]`, {
    speed: 900,
    threshold: 10,
    slidesPerView: 'auto',
    freeMode: true,
    mousewheel: {
      sensitivity: 0.5,
    },
  });
}
