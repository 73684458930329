import { OBSERVER } from '../plugins';

// Retourne si le site est ouvert dans un appareil mobile
export function isMobile() {
  let condition = false;

  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    condition = true;
  }

  return condition;
}

// Retourne le offset d'un élément dans la page
export function getElementOffset(options) {
  let box;

  if (typeof options.element === 'string') {
    box = document.querySelector(options.element).getBoundingClientRect();
  } else {
    box = options.element.getBoundingClientRect();
  }

  const { body } = document;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}

// Retourne le poids d'un fichier
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 ko';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Octets', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

// Retourne si un élément est visible dans le viewport
export function isVisible(el) {
  const rect = el.getBoundingClientRect();

  const bottom = rect.bottom >= 0;
  const right = rect.right >= 0;
  const top = rect.top <= (window.innerHeight || document.documentElement.clientHeight);
  const left = rect.left <= (window.innerWidth || document.documentElement.clientWidth);

  return bottom && right && top && left;
}

// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try {
      // prevent exception on browsers not supporting DOM styleSheets properly
      let k;
      const { styleSheets } = document;
      const styleSheetsLength = styleSheets.length;
      for (k = 0; k < styleSheetsLength; k += 1) {
        // for (const si in document.styleSheets) {
        const styleSheet = styleSheets[k];
        if (styleSheet.rules) {
          for (let ri = styleSheet.rules.length - 1; ri >= 0; ri -= 1) {
            if (styleSheet.rules[ri].selectorText) {
              if (styleSheet.rules[ri].selectorText.match(':hover')) {
                const { selectorText } = styleSheet.rules[ri];
                const araySelectorText = selectorText.split(',');
                let newSelectorText = '';

                // Garder slm les selectorText no :hover
                for (let i = 0; i < araySelectorText.length; i += 1) {
                  if (!araySelectorText[i].match(':hover')) {
                    newSelectorText += newSelectorText !== '' ? `, ${araySelectorText[i]}` : ` ${araySelectorText[i]}`;
                  }
                }

                // Enlver la règle si la seule classe est un :hover
                if (newSelectorText === '') {
                  styleSheet.deleteRule(ri);
                } else {
                  styleSheet.rules[ri].selectorText = newSelectorText;
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
}

// Permet de donner la hauteur exact en mobile de 100vh
// Css -> height: calc(var(--vh, 1vh) * 100);
export function hundredVH() {
  let ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  function setHeight() {
    // eslint-disable-next-line max-len
    const currentWw = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (ww !== currentWw) {
      ww = currentWw;
    }
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  function setDynamicHeight() {
    // eslint-disable-next-line max-len
    const currentWw = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (ww !== currentWw) {
      ww = currentWw;
    }
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--dynamic-vh', `${vh}px`);
  }

  OBSERVER.add({
    name: 'hundredVH',
    events: 'resize',
    targets: 'window',
    function: setDynamicHeight,
  });
  OBSERVER.on('hundredVH');

  setHeight();
  setDynamicHeight();
}

export function svgMask() {
  const svgHeight = 665;
  const svgWidth = 500;
  let count = -1;
  function replacer(match) {
    count += 1;
    let callback;
    if (count % 2) {
      callback = match / svgHeight;
    } else {
      callback = match / svgWidth;
    }
    return callback;
  }

  const svgPath = 'M330.5,530.5h-300c-16.6,0-30-13.4-30-30v-470c0-16.6,13.4-30,30-30h270c16.6,0,30,13.4,30,30V530.5z';
  // const nuPath = svgPath.replace('(\d+(\.\d+)?)', replacer);
  const nuPath = svgPath.replace(/(\d+(\.\d+)?)/g, replacer);
  console.log(nuPath);
}
