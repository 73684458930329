// data-font-size-based-on-caracters-count="{font-size}:min-max,{font-size}:min-max"
export default function fontSizeBasedOnCaracters() {
  const elements = document.querySelectorAll('[data-font-size-based-on-caracters-count]');
  const elementsLength = elements.length;

  let i;
  let j;

  for (i = 0; i < elementsLength; i += 1) {
    const element = elements[i];
    const elementTextLength = element.textContent.length;
    const fontSizesDataAttributeValue = element.getAttribute('data-font-size-based-on-caracters-count');
    const fontSizes = fontSizesDataAttributeValue.split(',');
    const fontSizesLength = fontSizes.length;

    for (j = 0; j < fontSizesLength; j += 1) {
      const fontSizeAndCount = fontSizes[j];
      const fontSizeAndCountArray = fontSizeAndCount.split(':');

      const fontSize = fontSizeAndCountArray[0];

      const countMinAndMax = fontSizeAndCountArray[1];
      const countMinAndMaxArray = countMinAndMax.split('-');
      const min = countMinAndMaxArray[0];
      const max = countMinAndMaxArray[1];

      if (elementTextLength >= min && elementTextLength <= max) {
        element.classList.add(`u-font-size-${fontSize}`);
      }
    }
  }
}
