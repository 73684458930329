import { OBSERVER } from '../plugins';

export default function alert(options = {}) {
  // Déclaration des propriétés par défaut
  const object = {};
  object.container = options.container || '[data-js="alerts"]';
  object.close = options.close || '[data-js="alerts-close"]';

  // Déclarations des variables
  const container = document.querySelector(object.container);

  // Déclarations des fonctions
  const closeAlert = () => document.documentElement.style.setProperty('--alerts-height', '0px');

  // Ajout de l événement pour masquer la bannière d'alertes
  OBSERVER.add({
    name: 'alerts',
    events: 'click',
    function: closeAlert,
    targets: object.close,
  });
  OBSERVER.on('alerts');

  // Si la page contient le container banner-alert
  if (typeof container === 'undefined' || container == null) {
    document.documentElement.style.setProperty('--alerts-height', '0px');
  }
}
