import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import { SCROLLFIRE, OBSERVER } from './plugins';
import defaultTransition from './transitions/default-transition';
import {
  asideHeight,
  backendKeyBind, calendar, initParallax, listLoaded, ogContent,
} from './functions/functions';
import { rubrics, promoRubrics } from './functions/rubrics';
import dynamicPackage from './functions/dynamic';
import { resetDropzone, formsPackage } from './functions/form';
import {
  overlayDropdown, overlayMenu, overlayPopup, overlaySearch, overlayShare,
} from './functions/overlays';
import Overlay from './vendor/overlay';
import {
  formContact,
  formExample,
  formNewsletter,
  formSuccess,
} from './functions/validation';
import masksPackage from './functions/masks';
import { hundredVH, removeHoverOnMobile } from './functions/helper';
import flippingText from './functions/flipping-text';
import fontSizeBasedOnCaracters from './functions/font-size-based-on-caracters';
import { dynamicScrollToBlock } from './functions/scrollToBlock';
import {
  quotesSlider, partnersSlider, filtersSlider, stepsSlider, homeBannerSlider,
} from './functions/sliders';
import dynamicClearInput from './functions/dynamicClearInput';
import alert from './functions/alert';

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;
window.listLoaded = listLoaded;

const initBarba = () => {
  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    hundredVH();
    overlayShare();
    overlayDropdown();
    overlayMenu();
    overlayPopup();
    overlaySearch();
    backendKeyBind();
    flippingText();
    alert();
    formsPackage();
    dynamicClearInput();
    dynamicScrollToBlock();
    SCROLLFIRE.init();
    removeHoverOnMobile();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [defaultTransition()],

    views: [
      {
        namespace: 'standardView',
        beforeEnter() {
          rubrics();
          promoRubrics();
          fontSizeBasedOnCaracters();
          masksPackage();
          dynamicPackage();
          formExample();
          calendar();
          asideHeight();
          quotesSlider();
          partnersSlider();
          initParallax();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          fontSizeBasedOnCaracters();
          dynamicPackage();
          asideHeight();
          filtersSlider();
          initParallax();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'searchResultView',
        beforeEnter() {
          fontSizeBasedOnCaracters();
          filtersSlider();
          initParallax();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsDetailView',
        beforeEnter() {
          fontSizeBasedOnCaracters();
          dynamicPackage();
          asideHeight();
          initParallax();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'sectionView',
        beforeEnter() {
          fontSizeBasedOnCaracters();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'newsletterView',
        beforeEnter() {
          formNewsletter();
        },
        afterLeave() { },
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          masksPackage();
          formContact();
        },
        afterLeave() { },
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          dynamicPackage();
          initParallax();
          fontSizeBasedOnCaracters();
          stepsSlider();
          quotesSlider();
          homeBannerSlider();
          partnersSlider();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: 'maintenanceView',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: '404View',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
      {
        namespace: '500View',
        beforeEnter() {
          dynamicPackage();
          window.lazyload = new LazyLoad();
        },
        afterLeave() {
          window.lazyload.destroy();
        },
      },
    ],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
