import { OBSERVER } from '../plugins';

export default function sliderBar(sliderName, slider) {
  const bar = document.querySelector(`[data-swiper-pagination="${sliderName}"]`);
  if (bar) {
    const dot = bar.querySelector('span');
    let dotWidth;

    const onResize = () => {
      dotWidth = dot.offsetWidth;
      bar.style.setProperty('--slider-bar-dot-width', `${dotWidth}px`);
      bar.style.setProperty('--slider-bar-dot-left', `${dotWidth * slider.realIndex}px`);
    };

    onResize();

    OBSERVER.add({
      name: 'sliderBar',
      events: 'resize',
      function: onResize,
    });

    OBSERVER.on('sliderBar');

    slider.on('transitionStart', () => {
      bar.style.setProperty('--slider-bar-dot-left', `${dotWidth * slider.realIndex}px`);
    });
  }
}
