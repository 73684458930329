import anime from 'animejs';
import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import Overlay from '../vendor/overlay';

export function overlayDropdown() {
  const overlayName = 'dropdown';

  if (document.querySelector(`[data-overlay="${overlayName}"]`)) {
    const overlay = new Overlay({
      name: overlayName,
      click: {
        buttons: {
          trigger: `[data-overlay-trigger="${overlayName}"]`,
          close: `[data-overlay-close="${overlayName}"]`,
          switch: '[data-overlay-toggle="search"], [data-overlay-trigger="menu"]',
        },
      },
      animations: {
        selector: '.js-overlay-dropdown-container',
        styles: [
          {
            property: 'height',
            value: 'dataset',
            easing: 'easeOutCubic',
          },
        ],
      },
      options: {
        speed: 800,
        goToSelector: 'html, body',
      },
    });

    overlay.init();
  }
}

export function overlayMenu() {
  const overlayName = 'menu';

  if (document.querySelector(`[data-overlay="${overlayName}"]`)) {
    const overlay = new Overlay({
      name: overlayName,
      events: {
        close: true,
        trigger: true,
      },
      click: {
        buttons: {
          trigger: `[data-overlay-trigger="${overlayName}"]`,
          close: `[data-overlay-close="${overlayName}"]`,
        },
      },
      options: {
        speed: 800,
        closeOnResize: false,
        goToSelector: 'html, body',
      },
    });

    overlay.init();

    const onTrigger = () => {
      anime.timeline({
        targets: '.c-overlay-menu__section',
        duration: 1,
        delay: 600,
        easing: 'easeInOutQuart',
      }).add({ scrollTop: 0 });
    };

    OBSERVER.add({
      name: 'overlayMenu',
      events: 'onTriggerOverlayMenu',
      function: onTrigger,
    });
    OBSERVER.on('overlayMenu');
  }
}

export function overlaySearch() {
  const overlayName = 'search';

  if (document.querySelector(`[data-overlay="${overlayName}"]`)) {
    const overlay = new Overlay({
      name: overlayName,
      events: {
        open: true,
        close: true,
        openComplete: true,
      },
      click: {
        buttons: {
          toggle: `[data-overlay-toggle="${overlayName}"]`,
          close: `[data-overlay-close="${overlayName}"]`,
          switch: '[data-overlay-trigger="dropdown"], [data-overlay-trigger="menu"]',
        },
      },
      animations: {
        selector: '.js-overlay-search-container',
        styles: [
          {
            property: 'height',
            value: '100%',
            easing: 'easeOutCubic',
          },
        ],
      },
      options: {
        speed: 800,
        goToSelector: 'html, body',
        closeOnResize: false,
      },
    });

    overlay.init();

    const onOpen = () => {
      document.querySelectorAll(`[data-overlay-toggle=${overlayName}]`).forEach((element) => element.setAttribute('title', 'Fermer la recherche'));
    };

    const onClose = () => {
      document.querySelectorAll(`[data-overlay-toggle=${overlayName}]`).forEach((element) => element.setAttribute('title', 'Ouvrir la recherche'));
    };

    const onFocus = () => {
      document.getElementById('form-search-input1').focus();
    };

    OBSERVER.add({
      name: 'overlaySearch',
      events: 'onOpenOverlaySearch',
      function: onOpen,
    });
    OBSERVER.add({
      name: 'overlaySearch',
      events: 'onCloseOverlaySearch',
      function: onClose,
    });
    OBSERVER.add({
      name: 'overlaySearch',
      events: 'onOpenCompleteOverlaySearch',
      function: onFocus,
    });
    OBSERVER.on('overlaySearch');
  }
}

export function overlayPopup() {
  const overlayName = 'popup';

  if (document.querySelector(`[data-overlay="${overlayName}"]`)) {
    const overlay = new Overlay({
      name: 'popup',
      click: {
        buttons: {
          close: `[data-overlay-close="${overlayName}"]`,
        },
      },
      timeout: {
        delay: 2000,
      },
      options: {
        speed: 800,
        closeOnResize: false,
      },
    });

    overlay.init();
  }
}

export function overlayShare() {
  function confirmClipboardCopy() {
    // eslint-disable-next-line no-alert
    alert('L’adresse URL a bien été copiée dans votre presse-papier.');
  }

  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }

  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ');
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    ShareButtons.update();
  }

  const overlayName = 'share';

  if (document.querySelector(`[data-overlay="${overlayName}"]`)) {
    const overlay = new Overlay({
      name: 'share',
      click: {
        buttons: {
          open: `[data-overlay-open="${overlayName}"]`,
          close: `[data-overlay-close="${overlayName}"]`,
        },
      },
      options: {
        speed: 800,
      },
    });

    overlay.init();

    OBSERVER.add({
      name: 'confirmClipboardCopy',
      events: 'click',
      targets: '.js-share-copy',
      function: confirmClipboardCopy,
    });
    OBSERVER.on('confirmClipboardCopy');
    OBSERVER.add({
      name: 'openShareOverlay',
      events: 'click',
      targets: `[data-overlay-open="${overlayName}"]`,
      function: openShareOverlay,
    });
    OBSERVER.on('openShareOverlay');
    OBSERVER.add({
      name: 'closeShareOverlay',
      events: 'click',
      targets: '.c-overlay-share__background, .c-overlay-share__close',
      function: closeShareOverlay,
    });
    OBSERVER.on('closeShareOverlay');
  }
}
